<template>
  <div>
    <NuxtLayout
      :fallback="(`${DEFAULT_PORTAL_LAYOUT}-default` as LayoutKey)"
      :name="(`${customization?.layout || DEFAULT_PORTAL_LAYOUT}-default` as LayoutKey)"
    >
      <PageSection vertical-align="center">
        <PortalCard
          :margin="`var(--kui-space-0, ${KUI_SPACE_0}) var(--kui-space-auto, ${KUI_SPACE_AUTO})`"
          max-width="460px"
        >
          <template #default>
            <h1
              v-show="!verifyingEmail && !idpIsLoading && !samlProcessing"
              data-testid="page-title"
            >
              {{ t('pages.login.title') }}
            </h1>

            <div
              v-if="verifyingEmail || idpIsLoading || samlProcessing"
              class="login-form-loading-state"
              data-testid="login-form-loading-state"
            >
              <ProgressIcon
                :aria-label="formLoadingAriaLabel"
                as="div"
                size="80px"
              />
              <p v-if="verifyingEmail">
                {{ t('pages.login.verifying_email') }}
              </p>
            </div>
            <div v-else>
              <PortalKAlert
                v-if="successMessage"
                appearance="success"
                class="success-alert"
                show-icon
              >
                {{ successMessage }}
              </PortalKAlert>
              <PortalKAlert
                v-if="loginError || oidcCallbackError"
                appearance="danger"
                class="login-error-alert"
                data-testid="login-error"
                show-icon
              >
                {{ loginError || oidcCallbackError }}
              </PortalKAlert>

              <form
                v-if="forceBasicAuth || context?.basic_auth_enabled || (!context?.basic_auth_enabled && !context?.oidc_auth_enabled && !context?.saml_auth_enabled)"
                class="login-form"
                data-testid="login-form"
                novalidate
                @submit.prevent="login"
              >
                <div>
                  <KInput
                    id="email"
                    v-model.trim="formData.username"
                    autocapitalize="off"
                    autocomplete="username"
                    data-testid="email"
                    :error="usernameHasError"
                    :label="t('pages.login.form.email')"
                    :placeholder="t('pages.login.form.email_placeholder')"
                    :readonly="submitting"
                    required
                    type="email"
                  />
                </div>
                <div>
                  <KInput
                    id="password"
                    v-model.trim="formData.password"
                    autocomplete="current-password"
                    data-testid="password"
                    :error="passwordHasError"
                    :label="t('pages.login.form.password')"
                    :placeholder="t('pages.login.form.password_placeholder')"
                    :readonly="submitting"
                    required
                    type="password"
                  />
                  <div class="forgot-password-link">
                    <NuxtLink href="/forgot-password">
                      {{ t('pages.login.form.forgot_password') }}
                    </NuxtLink>
                  </div>
                </div>

                <PortalKButton
                  appearance="primary"
                  data-testid="submit-button"
                  :disabled="!loginButtonEnabled"
                  size="large"
                  type="submit"
                >
                  <ProgressIcon
                    v-if="submitting"
                    decorative
                    :size="KUI_ICON_SIZE_30"
                  />
                  {{ submitting ? t('actions.logging_in') : t('actions.log_in') }}
                </PortalKButton>
              </form>
              <div
                v-if="context?.basic_auth_enabled"
                class="form-footer"
              >
                <i18n-t
                  class="form-footer-text"
                  keypath="pages.login.sign_up"
                  scope="global"
                  tag="div"
                >
                  <template #link>
                    <NuxtLink
                      class="form-footer-link"
                      data-testid="register-link"
                      href="/register"
                    >
                      {{ t('pages.login.sign_up_link') }}
                      <ArrowRightIcon
                        as="span"
                        decorative
                        display="inline-block"
                        :size="KUI_ICON_SIZE_30"
                      />
                    </NuxtLink>
                  </template>
                </i18n-t>
              </div>

              <div
                v-if="formDividerVisible"
                class="login-form-divider"
              >
                {{ t('pages.login.form_divider') }}
              </div>

              <div v-if="context?.oidc_auth_enabled || context?.saml_auth_enabled">
                <PortalKButton
                  appearance="secondary"
                  :aria-label="!idpIsLoading && !samlProcessing ? t('pages.login.oidc.continue_aria_label') : undefined"
                  class="sso-login-button"
                  data-testid="sso-login-button"
                  :disabled="oidcLoginButtonDisabled || idpIsLoading || samlProcessing || submitting"
                  size="large"
                  @click.prevent="redirectToIdp()"
                >
                  <ProgressIcon
                    v-if="idpIsLoading || samlProcessing"
                    class="spin-icon"
                    decorative
                    :size="KUI_ICON_SIZE_30"
                  />
                  <ProfileIcon
                    v-else
                    decorative
                    :size="KUI_ICON_SIZE_30"
                  />
                  <!-- TODO: Customers likely want to customize this button text -->
                  <span>{{ t('pages.login.oidc.continue') }}</span>
                </PortalKButton>
              </div>
            </div>
          </template>
        </PortalCard>
      </PageSection>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { ProgressIcon, ArrowRightIcon, ProfileIcon } from '@kong/icons'
import { KUI_ICON_SIZE_30, KUI_SPACE_0, KUI_SPACE_AUTO } from '@kong/design-tokens'
import type { LayoutKey } from '#build/types/layouts'
import { titleCase } from 'scule'

const { context, customization } = storeToRefs(usePortalStore())
const sessionStore = useSessionStore()
const { session } = storeToRefs(sessionStore)
const route = useRoute('login-login_path')
const { $portalApi } = useNuxtApp()
const { t } = useI18n()
const requestURL = useRequestURL()

const formData = reactive<PortalApiRequestBodyTemp<'authenticate'>>({
  username: '',
  password: '',
})
const successMessage = useState<string>(() => '')
const setSuccessMessage = (type: string): void => {
  if (!type || typeof type !== 'string') {
    successMessage.value = ''
    return
  }

  switch (type) {
    case 'register':
      successMessage.value = t('pages.login.success.register')
      break
    case 'reset-password':
      successMessage.value = t('pages.login.success.reset_password')
      break
  }
}

// Is the page in the process of verifying an email address
const verifyingEmail = useState<boolean>('verifying-email', () => false)
// Is the form submitting
const submitting = useState<boolean>('login-submitting', () => false)

// oidc login states
const oidcLoginButtonDisabled = useState<boolean>('sso-login-button-disabled', () => false)
const forceBasicAuth = useState<boolean>('login-force-basic-auth', () => false)
const formDividerVisible = computed((): boolean => ((context.value?.basic_auth_enabled && (context.value?.oidc_auth_enabled || context.value?.saml_auth_enabled)) || forceBasicAuth.value))
const oidcCallbackError = useState<string>('sso-callback-error', () => '')
// Setup and automatically trigger IDP (or ignore it, depending on the props)
// Passing the refs on purpose so values are reactive.
const { idpIsLoading, redirectToIdp } = useIdentityProvider(
  computed((): boolean => (context.value?.oidc_auth_enabled || context.value?.saml_auth_enabled) || false),
)
// Is a SAML login processing
const samlProcessing = computed((): boolean => !!context.value?.saml_auth_enabled && route.query.loginSuccess === 'true' && route.query.method === 'saml' && !route.query.refreshed)

const loginButtonEnabled = computed((): boolean => !submitting.value && !idpIsLoading.value && !samlProcessing.value)
const usernameHasError = computed((): boolean => !!loginError.value && !loginError.value.toLowerCase().includes('token') && (!formData.username || loginError.value.toLowerCase().includes('email')))
const passwordHasError = computed((): boolean => !!loginError.value && !loginError.value.toLowerCase().includes('token') && (!formData.password || loginError.value.toLowerCase().includes('password')))
const loginError = useState<string>('login-error', () => '')
const formLoadingAriaLabel = computed((): string | undefined => {
  if (verifyingEmail.value) {
    return t('pages.login.verifying_email')
  }

  if (idpIsLoading.value || samlProcessing.value) {
    return t('pages.login.sso_loading')
  }

  return undefined
})

const login = async (): Promise<void> => {
  try {
    // Reset state
    loginError.value = ''
    setSuccessMessage('')

    if (!formData.username || !formData.password) {
      loginError.value = t('errors.general.required_fields')
      return
    }

    submitting.value = true

    // Important: Must call a proxied endpoint in order to rewrite cookies; just use native $fetch
    await $fetch('/api/session/authenticate', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        // Add custom Portal Client headers for dynamic request routing
        'x-portal-context-origin': requestURL.origin,
        'x-portal-context-hostname': requestURL.hostname,
      },
      body: {
        username: formData.username,
        password: formData.password,
      },
    })

    // Fetch additional data after login and redirect the user
    await onLoginSuccess()
    // Do not reset submitting state here, as we want to keep the form disabled if successful
  } catch (error: any) {
    submitting.value = false
    loginError.value = parseApiError(error) || t('errors.authentication.failed')
  }
}

const onLoginSuccess = async (): Promise<void> => {
  try {
    // Retrieve the developer's session data
    const developerData = await $portalApi('/api/v3/developer/me', {
      method: 'GET',
    })

    // Store the developer data in the session store
    session.value.developer = developerData
  } catch (error: any) {
    submitting.value = false
    loginError.value = parseApiError(error) || t('errors.authentication.failed')

    return
  }

  // Important: Set the authenticated session value to true (this will also refresh the portal config data)
  session.value.authenticated = true

  // Store the login path locally before we clear the cookie
  const userReturnToPath = sessionStore.getLoginReturnPath() || '/'
  // Clear the stored return path cookie
  sessionStore.setLoginReturnPath(null)
  // Redirect the user to the path they were trying to access before logging in, or the homepage
  await navigateTo(userReturnToPath)
}

const verifyEmailAddress = async (token: string): Promise<void> => {
  try {
    verifyingEmail.value = true

    // Important: Must call a proxied endpoint in order to rewrite cookies; just use native $fetch
    const verificationResponse = await $portalApi('/api/v3/developer/verify-email', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: {
        token,
      },
    })

    formData.username = verificationResponse?.email || ''
    const resetPasswordToken: string = verificationResponse?.token || ''

    await navigateTo({
      path: '/reset-password',
      query: {
        email: formData.username,
        token: resetPasswordToken,
      },
    })
  } catch (error: any) {
    setSuccessMessage('')
    loginError.value = parseApiError(error) || t('errors.authentication.verify_email')
    verifyingEmail.value = false
  }
}

definePageMeta({
  // Grabs the route the user arrived from and stores it in the session store in order to redirect them back after login
  middleware: 'login',
})

useSeoMeta({
  title: t('pages.login.title'),
  description: t('pages.login.description'),
})

onMounted(async () => {
  // If a token is present in the route query, attempt to verify their email address
  if (String(route.query.token || '')) {
    // Verify email address and set email on success
    await verifyEmailAddress(String(route.query.token || ''))
    return
  }

  // Set the email if in route query
  if (String(route.query.email || '')) {
    formData.username = String(route.query.email || '')
  }

  // If basicAuth query parameter is present, force-show the basic auth form (for org admins)
  if (String(route.query.basicAuth || '') === 'true') {
    forceBasicAuth.value = true
  }

  // If coming from a successful event
  if (String(route.query.success || '')) {
    setSuccessMessage(String(route.query.success || ''))
  }

  if (String(route.query.loginError || '')) {
    oidcCallbackError.value = decodeURIComponent(String(route.query.loginError || ''))
  }

  // &error=access_denied&error_description=User+is+not+assigned+to+the+client+application.
  if (String(route.query.error || '') && String(route.query.error_description || '')) {
    oidcCallbackError.value = decodeURIComponent(titleCase(String(route.query.error || '')) + ': ' + String(route.query.error_description || ''))
  }

  /**
   * If a hash is present when the login page loads, it's likely because the user was
   * redirected here after attempting to visit another page.
   *
   * Grab the hash and append it to the stored login return path, then remove the hash from the current URL.
   */
  if (route.hash) {
    const loginReturnPath = sessionStore.getLoginReturnPath()

    // If there is a stored login return path and it does not already include a hash
    if (loginReturnPath && !loginReturnPath.includes('#')) {
      // Append the new hash to the existing login return path, ensuring to not add an extra hash
      const newReturnPath = `${loginReturnPath.split('#')[0]}${route.hash}`
      // Set the new return path
      sessionStore.setLoginReturnPath(newReturnPath)
    }
    await useRouter().replace({ hash: '' })
  }
})

onBeforeUnmount(() => {
  // Always reset state
  submitting.value = false
  loginError.value = ''
  oidcCallbackError.value = ''
  // Always reset email verification state
  verifyingEmail.value = false

  if (String(route.query.success || '')) {
    setSuccessMessage(String(route.query.success || ''))
  } else {
    // Clear success message if user leaves and comes back to route
    setSuccessMessage('')
  }
})
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  gap: var(--kui-space-70, $kui-space-70);
}

.success-alert,
.login-error-alert {
  margin-bottom: var(--kui-space-70, $kui-space-70);
}

.forgot-password-link {
  font-size: var(--kui-font-size-20, $kui-font-size-20);
  margin-top: var(--kui-space-30, $kui-space-30);
}

.form-footer {
  margin-top: var(--kui-space-70, $kui-space-70);
  text-align: center;
}

.form-footer-text {
  align-items: center;
  display: flex;
  gap: var(--kui-space-30, $kui-space-30);
  justify-content: center;
}

.form-footer-link {
  align-items: center;
  display: flex;
  gap: var(--kui-space-10, $kui-space-10);
}

.login-form-loading-state {
  align-items: center;
  color: var(--kui-color-text-primary, $kui-color-text-primary);
  display: flex;
  flex-direction: column;
  gap: var(--kui-space-70, $kui-space-70);
  justify-content: center;
  margin: var(--kui-space-100, $kui-space-100) var(--kui-space-auto, $kui-space-auto);
}

.sso-login-button {
  width: 100%;
}

.login-form-divider {
  align-items: center;
  color: var(--kui-color-text-neutral, $kui-color-text-neutral);
  display: flex;
  flex-direction: row;
  font-size: var(--kui-font-size-40, $kui-font-size-40);
  justify-content: center;
  margin: var(--kui-space-80, $kui-space-80) var(--kui-space-auto, $kui-space-auto);
  text-transform: uppercase;

  &:before,
  &:after {
    background: var(--kui-color-background-neutral-weak, $kui-color-background-neutral-weak);
    content: '';
    flex: 1;
    height: 1px;
    margin: var(--kui-space-0, $kui-space-0);
  }

  &:before {
    margin-right: var(--kui-space-50, $kui-space-50);
  }

  &:after {
    margin-left: var(--kui-space-50, $kui-space-50);
  }
}
</style>
